import React from "react";
import { educacions,experiencias,nuevos,valores } from "../data";

export default function Skills (){
    return(
<div id="skills" className="experiencia-area">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="expert-content-info">
                    <div className="site-heading">
                        <span className="site-title-tagline"><i class="fa fa-book"></i> Nosotros somos</span>
                        <h1>gosofox</h1>
                        <h2 class="site-title">
                            Goso<span>Fox</span> VanguardiaDigital
                        </h2>
                    </div>
                    <p className="parrafo">
                    En nuestra agencia de marketing digital, nos esforzamos por ser líderes en la industria al fusionar la innovación
                    y la tecnología de vanguardia para ofrecer soluciones creativas y efectivas a nuestros clientes. 
                    Nos vemos a nosotros mismos como pioneros en la transformación digital, aprovechando las últimas tendencias
                    y herramientas tecnológicas para impulsar el éxito de las marcas en un mundo cada vez más conectado.
                    </p><br />
                    <a href="https://wa.link/gyv3ph" target="_blank" class="btn-principal">Quiero empezar ahora <i class="fa fa-send"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                <div class="expert-content-wrapper">
                    <ul class="nav nav-pills" id="pills-tab-expert" role="tablist">
                        <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-tab-skill" data-bs-toggle="pill" data-bs-target="#pills-skill" type="button" role="tab" aria-controls="pills-skill" aria-selected="true">
                            <a>Misión</a></button>
                        </li>
                        <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-tab-education" data-bs-toggle="pill" data-bs-target="#pills-education" type="button" role="tab" aria-controls="pills-education" aria-selected="false" tabindex="-1">
                        <a>Visión</a></button>
                        </li>
                        <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-tab-experience" data-bs-toggle="pill" data-bs-target="#pills-valores" type="button" role="tab" aria-controls="pills-valores" aria-selected="false" tabindex="-1">
                            <a>Valores</a></button>
                        </li>
                        
                    </ul>

                    <div class="tab-content" id="pills-tabContent-expert">
                        <div class="tab-pane fade show active" id="pills-skill" role="tabpanel" aria-labelledby="pills-tab-skill" tabindex="0">
                            <div class="expert-experience">
                                <div class="row">
                                    {experiencias.map((experiencia) => (
                                        <div class="col-md-12">                                   
                                            <div class="expert-item">
                                                <img
                                                className="absolute object-cover object-center"
                                                src={experiencia.image} width={120}
                                                /> 
                                                <p>{experiencia.empresa}</p>
                                                {/* <h4>{experiencia.cargo}</h4> */}
                                            </div>
                                        </div>  
                                    ))}                    
                                </div>
                            </div> 
                        </div> 


                        <div class="tab-pane fade" id="pills-education" role="tabpanel" aria-labelledby="pills-tab-education" tabindex="0">
                            <div className="expert-education">
                                <div class="row">
                                {educacions.map((educacion) => (
                                    <div class="col-md-12">                                   
                                        <div className="expert-item">
                                            <img
                                                className="absolute object-cover object-center"
                                                src={educacion.image} width={200}
                                                /> 
                                            <p>{educacion.instituto}</p>
                                        </div>
                                    </div>  
                                    ))}                     
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="pills-valores" role="tabpanel" aria-labelledby="pills-tab-valores" tabindex="0">
                            <div className="expert-valores">
                                <div class="row">
                                {valores.map((valores) => (
                                    <div class="col-md-4">                                   
                                        <div className="expert-item">
                                            <p className="item">{valores.empresa}</p>
                                        </div>
                                    </div>  
                                    ))}                     
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>

    )
}
