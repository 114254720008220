import React from "react";

export default function procesos(){
    return(
    <div id="procesos" className="procesos">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">                    
                    <div class="site-heading">
                        <span class="site-title-tagline"><i class="fa fa-book"></i> 5 Procesos</span>
                        <h1>Procesos</h1>
                        <h2 class="site-title">
                        A solo 5 pasos para tu <span>éxito</span>  en linea
                        </h2>
                        <p>Inicio sencillo, resultado excepcional.<br />  Comunicación ágil, reuniones mínimas para maximizar tiempo 
                        y eficiencia en cada paso.
                        </p><br />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2.3 card-contenido ">
                    <div className="container-glass">
                        <div className="card-glass">
                            <div className="content-glass">
                                <h2>01</h2>
                                <img  src="./imagenes/consulta.png" width={80}></img>
                                <h3>Consulta</h3>
                                <p>Háblanos de tu proyecto.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2.3 card-contenido">
                    <div className="container-glass">
                        <div className="card-glass">
                            <div className="content-glass">
                                <h2>02</h2>
                                <img  src="./imagenes/estimado.png" width={74}></img>
                                <h3>Estimado</h3>
                                <p>Realizaremos la cotización del proyecto.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2.3 card-contenido">
                    <div className="container-glass">
                        <div className="card-glass">
                            <div className="content-glass">
                                <h2>03</h2>
                                <img  src="./imagenes/aprovado.png" width={129}></img>
                                <h3>Aprovado</h3>
                                <p>Realizaremos la cotización del proyecto.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2.3 card-contenido">
                    <div className="container-glass">
                        <div className="card-glass">
                            <div className="content-glass">
                                <h2>04</h2>
                                <img  src="./imagenes/desing_dev.png" width={95}></img>
                                <h3>Design & Develop</h3>
                                <p>Diseño de UI y desarrollo interactivo en React, JS, HTMLC Y CSS.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2.3 card-contenido">
                    <div className="container-glass">
                        <div className="card-glass">
                            <div className="content-glass">
                                <h2>05</h2>
                                <img  src="./imagenes/lanzamiento.png" width={62}></img>
                                <h3>Lanzamiento</h3>
                                <p>Aprobación y lanzamiento de tu sitio.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
    )
}