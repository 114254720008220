import React from "react";

export default function Herramientas(){
    return(
    <div id="herramientas" className="herramientas">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">                    
                    <div class="site-heading">
                        <span class="site-title-tagline"><i class="fa fa-book"></i> ¿Necesitas ayuda?</span>
                        <h1>Nuestros servicios</h1>
                        <h2 class="site-title">
                        Prestación de <span>servicios</span> para nuestros clientes
                        </h2>
                        
                    </div>
                </div>
                </div>
                </div>
                <div class="col-lg-12">                
                    <div class="container01">
                        <div class="card a">
                            <span></span>
                            <div class="content">
                                <h2 >Diseño Gráfico Profesional</h2>
                                <ul>
                                <li>Identidad Corporativo</li>
                                <li>Tarjetas Personales y Corporativos</li>
                                <li>Brochure</li>
                                <li>Tarjeta de visita</li>
                                </ul>
                                <a href="https://wa.link/gyv3ph" target="_blank" className="btn-principal">Más Información <i class="fa fa-send"></i></a>
                            </div>
                        </div>

                        <div class="card b">
                            <span></span>
                            <div class="content">
                                <h2>Diseño y Desarrollo Web</h2>
                                <ul>
                                <li>Página de aterrizaje</li>
                                <li>Diseño de pagina corporativo</li>
                                <li>Ecommerce</li>
                                <li>Sistemas a tu medida</li>
                                </ul>
                                <a href="https://wa.link/gyv3ph" target="_blank" className="btn-principal">Más Información <i class="fa fa-send"></i></a>
                            </div>
                        </div>

                        <div class="card c">
                            <span></span>
                            <div class="content">
                                <h2>Marketing <br></br>Digital</h2>
                                <ul>
                                <li>Mercadotecnia en redes sociales</li>
                                <li>Marketing de marca</li>
                                <li>Marketing por correo</li>
                                <li>Pauta Publicitaria</li>
                                </ul>
                                <a href="https://wa.link/gyv3ph" target="_blank" className="btn-principal">Más Información <i class="fa fa-send"></i></a>
                            </div>
                        </div>

                        <div class="card d">
                            <span></span>
                            <div class="content">
                                <h2>Servicios de<br></br> SEO</h2>
                                <ul>
                                <li>SEO web</li>
                                <li>Google Ads</li>
                                <li>Facebook Ads</li>
                                <li>Optimización Web</li>
                                </ul>
                                <a href="https://wa.link/gyv3ph" target="_blank" className="btn-principal">Más Información <i class="fa fa-send"></i></a>
                            </div>
                        </div>

                        <div class="card d">
                            <span></span>
                            <div class="content">
                                <h2>Soporte Técnico Remoto-Presencial</h2>
                                <ul>
                                <li>Instalaciones de programas</li>
                                <li>Antivirus </li>
                                <li>Diagnostico </li>
                                <li>Mantenimiento </li>
                                </ul>
                                <a href="https://wa.link/gyv3ph" target="_blank" className="btn-principal">Más Información <i class="fa fa-send"></i></a>
                            </div>
                        </div>                    

                      
                    </div>
                </div>
            </div>

  

    )
}