import React from "react";
import {blogs} from "../data";
import Carousel from 'react-bootstrap/Carousel';

export default function Blogs () {
  return (
    <div id="blogs" className="blogs">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div className="blogs-titulo">
                        <div class="site-heading">
                            <span class="site-title-tagline"><i class="fa fa-book"></i> Enterate con GosoFox</span>
                            <h1>Enterate </h1>
                            <h2 class="site-title">
                            Mantente <span>actualizado</span>
                            </h2>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row glass-card-conte">
                        <Carousel>
                            <Carousel.Item>
                                <div className='row'> 
                                <div class="col-lg-4 col-md-6 col-sm-12 glass">
                                    <div class="glass-card">
                                        <h3 class="glass-card-title">
                                            <a href="https://blog.soyhenry.com/que-es-javascript-y-para-que-sirve">Desarrollo Web</a>
                                        </h3>
                                        <div class="tags">
                                            <a href="https://blog.soyhenry.com/que-es-javascript-y-para-que-sirve" target="_blank" rel="tag">¿Qué es JavaScript y para qué sirve</a>
                                        </div>
                                        <p>
                                        Un lenguaje de programación es un sistema estructurado de instrucciones, el cual está 
                                        conformado por conjuntos de símbolos, palabras claves, reglas semánticas y sintácticas que 
                                        permiten el entendimiento entre un programador y una máquina.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 glass">
                                    <div class="glass-card">
                                        <h3 class="glass-card-title">
                                            <a href="https://appmaster.io/es/blog/creadores-de-sitios-web-vs-wordpress">Desarrollo Web</a>
                                        </h3>
                                        <div class="tags">
                                            <a href="https://appmaster.io/es/blog/creadores-de-sitios-web-vs-wordpress" target="_blank" rel="tag">Creadores de sitios web vs WordPress</a>
                                        </div>
                                        <p>
                                          Opciones frecuentes a la hora de crear un sitio web: utilizar un creador de sitios web o WordPress. 
                                          Ambos tienen sus pros y sus contras, y elegir el más adecuado para tu proyecto puede resultar desalentador.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 glass">
                                    <div class="glass-card">
                                        <h3 class="glass-card-title">
                                            <a href="https://sitechecker.pro/es/hosting-checker/">Seguridad Informatica</a>
                                        </h3>
                                        <div class="tags">
                                            <a href="https://sitechecker.pro/es/hosting-checker/" target="_blank" rel="tag">Cómo verificar quién hosting un sitio web</a>
                                        </div>
                                        <p>
                                        De vez en cuando, puede notar algunos sitios web que le interesan. Por ejemplo, podría ser el sitio de su nuevo competidor 
                                        o simplemente algún portal web con una velocidad de carga excelente (o inferior).
                                        </p>
                                    </div>
                                </div>
                                </div>
                            </Carousel.Item>

                            <Carousel.Item>
                                <div className='row'> 
                                <div class="col-lg-4 col-md-6 col-sm-12 glass">
                                    <div class="glass-card">
                                        <h3 class="glass-card-title">
                                            <a href="https://appmaster.io/es/blog/generador-de-consultas-sql">Base de datos</a>
                                        </h3>
                                        <div class="tags">
                                            <a href="https://appmaster.io/es/blog/generador-de-consultas-sql" target="_blank" rel="tag">Generador de consultas SQL</a>
                                        </div>
                                        <p>
                                        Como desarrollador o administrador de bases de datos, la gestión y consulta de grandes conjuntos de datos puede 
                                        resultar desalentadora. Las consultas SQL tradicionales pueden ser complejas y llevar mucho tiempo...
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 glass">
                                    <div class="glass-card">
                                        <h3 class="glass-card-title">
                                            <a href="#">Marketing Digital</a>
                                        </h3>
                                        <div class="tags">
                                            <a href="#" target="_blank" rel="tag">12 Herramientas para crear contenidos digitales</a>
                                        </div>
                                        <p>
                                        Cada vez se pueden encontrar en el mercado más herramientas para crear contenidos digitales. Aplicaciones para crear infografías, para mejorar tus imágenes, ..
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 glass">
                                    <div class="glass-card">
                                        <h3 class="glass-card-title">
                                            <a href="https://www.mediodigital.com.uy/conoce-cuales-son-las-novedades-que-trae-instagram/">Marketing, Negocios</a>
                                        </h3>
                                        <div class="tags">
                                            <a href="https://www.mediodigital.com.uy/conoce-cuales-son-las-novedades-que-trae-instagram/" target="_blank" rel="tag">Novedades que Trae Instagram: ¿Cuáles Son?</a>
                                        </div>
                                        <p>
                                        Y si te gustan las plataformas digitales, lo mejor que puedes hacer es estar atent@ a cada nueva actualización. Como siempre lo hacemos, vamos a contarte las novedades de Instagram en 2023.
                                        </p>
                                    </div>
                                </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>

                    </div>

                    </div>
                    </div>
                    </div>
    </div>

    
  );
}