import './App.css';
import React from 'react';

import Header from "./components/header";
import Seccion from "./components/seccion"
import Procesos from "./components/procesos";
import Herramientas from './components/herramientas';
import Skills from './components/skills';
import Blogs from './components/blogs';
import Contacto from './components/contacto';
import Proyectos from './components/proyectos';
import Carousel from './components/carousel';

import Footer from "./components/footer";

export default function App() {
  return (
    <div className="App">
      <Header />
      <Seccion />
      <Skills />  
      <Herramientas />
      <Proyectos />
      <Procesos />
      {/* <Blogs /> */}
      <Carousel /> 
      <Contacto />
      <Footer /> 
    </div>
  );
}