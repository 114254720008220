import React from "react";

export default function Footer  () {
        return(
            <footer id="footer" className="footer">               
                <div className="container">        
                    <div className="row" >
                        <div className="col-md-4 col-sm-12 col-12 footer-contacto"> 
                            <div className="row">                           
                                <div className="col-md-2 col-xs-2 col-2  footer-contact-icon">
                                    <span class="fa fa-phone"></span>
                                </div> 
                                <div className="col-md-10 col-xs-10 col-10 footer-contact-content">
                                    <a href="tel:901363352">
                                    <p>Celular:</p>
                                    <h2>+51 901 363 352</h2>
                                    </a>  
                                </div>
                            </div>                                     
                        </div>   
                        <div className="col-md-4 col-sm-12 col-12 footer-contacto">
                            <div className="row">                         
                                <div className="col-md-2 col-xs-2 col-2 footer-contact-icon">
                                    <span class="fa fa-envelope"></span>
                                </div>   
                                <div className="col-md-10 col-xs-10 col-10 footer-contact-content">
                                    <a href="mailto:ventas@gosofox.com">
                                    <p>Correo:</p>
                                    <h2>ventas@gosofox.com</h2>
                                    </a>  
                                </div>
                            </div>        
                        </div>

                        <div className="col-md-4 col-sm-12 col-12 footer-redes">
                            <ul>
                                <div></div>
                                <li>
                                    <a href="https://www.facebook.com/gosofox" target="_blank">
                                        <span class="fa fa-facebook"></span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://wa.link/gyv3ph" target="_blank">
                                        <span class="fa fa-whatsapp"></span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/gosofox/?igsh=cWZ2NTZqYnBkZjMz" target="_blank">
                                        <span class="fa fa-instagram"></span>
                                    </a>
                                </li>
                                <div></div>
                            </ul>           
                        </div>                     
                    </div>            
                </div>
                
                <div className="footer-copyright">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 acerca-footer">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 logo">
                                    <img src="./imagenes/logo_gosofox1.png" width={300}></img>
                                </div>
                                <div className="col-md-12 col-sm-12 acercafooter">
                                    <p>En nuestra agencia de marketing digital, nos esforzamos por ser líderes en la industria al fusionar la innovación 
                                        y la tecnología de vanguardia para ofrecer soluciones creativas y efectivas a nuestros clientes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 menu-footer">
                            <h5 className="titulo">Servicios</h5>
                            <div className="divi"></div>
                            <br></br>
                            <p><a href="#"> Diseño Grafico Profesional <span class="fa fa-caret-left"></span></a></p>
                            <p><a href="#"> Diseño Web <span class="fa fa-caret-left"></span></a></p>
                            <p><a href="#"> Desarrollo de Software <span class="fa fa-caret-left"></span></a></p>
                            <p><a href="#"> Marketing Digital <span class="fa fa-caret-left"></span></a></p>
                            <p><a href="#"> Soporte Técnico Remoto - Presencial <span class="fa fa-caret-left"></span></a></p>


                        </div>       
                       
                        <div className="row dividir">
                            <div className="col-md-12 col-sm-12 col-xs-12 divcenter">
                                <span>© Copyright {new Date().getFullYear()} -- </span>
                                <span>
                                Diseñado por <a href="https://www.gosofox.com"> Gosofox</a>
                                </span>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                               
                            </div>
                        </div>
                    </div>                   
                </div>        
            </footer>                           
        )
    }
