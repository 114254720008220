import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    src: './imagenes/dis_grafico_banner.png',
    altText: 'Diseño Gráfico Profesional',
    caption: 'Nos encargamos de diseñar logotipos y marcas que representen la identidad de una empresa o proyecto. Incluso puedes diseñar interfaces de aplicaciones y sitios web, y siempre buscando el diseño mas profesional.',
    header: 'Diseño Gráfico Profesional',
  },
  {
    src: './imagenes/dis_web_banner.png',
    altText: 'Diseño y Desarrollo Web',
    caption: 'Diseñamos y desarrollamos lo que necesites para impulsar tu presencia en Internet, trabajando proyectos programados desde cero o desarrollados sobre plataformas reconocidas como WordPress.',
    header: 'Diseño y desarrollo web',

  },
  {
    src: './imagenes/marketing_banner.png',
    altText: 'Marketin Digital',
    caption: 'Gestionamos la presencia de tu marca para incrementar tu comunidad. mejorar el posicionamiento y conectar con tus clientes en los medios digitales donde ellos interactúan.',
    header: 'Marketin Digital',

  },
  {
    src: './imagenes/seo_banner.png',
    altText: 'Servicios de SEO',
    caption: 'Incrementamos el tráfico orgánico de tu página web con las mejores estrategias de optimización y posicionamiento en la web de tu empresa y conseguirás más clientes y más personas conocerán tus productos o servicios.',
    header: 'Servicios de SEO',

  },
  {
    src: './imagenes/soporte_banner.png',
    altText: 'Soporte Tecnico corporativo',
    caption: 'Brindamos un soporte tecnico personalizado que garantice la disponibilidad en apoyarlo y darlos solucion a las fallas que presenta sus equipos, con la atenciób inmediata de manera remota o presencial.',
    header: 'Soporte Técnico Corporativo', 

  }
];

class Example extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
   
          <div className='row banner'>
            <img src={item.src} altText={item.altText}  width=" 100%"/>
              <div className='col-lg-6 bot'>
                <a href="https://wa.link/gyv3ph" target="_blank" className="btn-principal">
                  Más Información <span className="fa fa-send"></span>
                </a> 
              </div>
              <div className='col-lg-6 site-heading'>
          
              </div>
          </div>
        </CarouselItem>
      );
    });

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    );
  }
}


export default Example;
