
export const blogs = [
    {
      title: "¿Qué es JavaScript y para qué sirve",
      subtitle: "Desarrollo Web",
      description:
        "Un lenguaje de programación es un sistema estructurado de instrucciones, el cual está conformado por conjuntos de símbolos, palabras claves, reglas semánticas y sintácticas que permiten el entendimiento entre un programador y una máquina.",
      link: "https://blog.soyhenry.com/que-es-javascript-y-para-que-sirve/",
    },
    {
      title: "Creadores de sitios web vs WordPress",
      subtitle: "Desarrollo Web",
      description:
        "Opciones frecuentes a la hora de crear un sitio web: utilizar un creador de sitios web o WordPress. Ambos tienen sus pros y sus contras, y elegir el más adecuado para tu proyecto puede resultar desalentador. ",
      link: "https://appmaster.io/es/blog/creadores-de-sitios-web-vs-wordpress",
    },
    {
      title: "Cómo verificar quién hosting un sitio web",
      subtitle: "Seguridad Informatica",
      description:
        "De vez en cuando, puede notar algunos sitios web que le interesan. Por ejemplo, podría ser el sitio de su nuevo competidor o simplemente algún portal web con una velocidad de carga excelente (o inferior).",
      link: "https://sitechecker.pro/es/hosting-checker/",
    },
    {
      title: "Generador de consultas SQL",
      subtitle: "Base de datos",
      description:
        "Como desarrollador o administrador de bases de datos, la gestión y consulta de grandes conjuntos de datos puede resultar desalentadora. Las consultas SQL tradicionales pueden ser complejas y llevar mucho tiempo...",
      link: "https://appmaster.io/es/blog/generador-de-consultas-sql",
    },
    {
      title: "12 Herramientas para crear contenidos digitales",
      subtitle: "Marketing Digital",
      description:
        "Cada vez se pueden encontrar en el mercado más herramientas para crear contenidos digitales. Aplicaciones para crear infografías, para mejorar tus imágenes, ...",
      link: "https://www.antevenio.com/blog/2016/09/12-herramientas-para-crear-contenidos-digitales/",
    },    {
      title: "Novedades que Trae Instagram en 2023: ¿Cuáles Son?",
      subtitle: "Marketing, Negocios y Empresa",
      description:
        "Y si te gustan las plataformas digitales, lo mejor que puedes hacer es estar atent@ a cada nueva actualización. Como siempre lo hacemos, vamos a contarte las novedades de Instagram en 2023.",
      link: "https://www.mediodigital.com.uy/conoce-cuales-son-las-novedades-que-trae-instagram/",
    },
  ];

  export const educacions = [
    {
      image: "./imagenes/vision.png",
      instituto: "Ser la fuerza impulsora detrás de la transformación digital de nuestros clientes, liderando con creatividad, innovación y un enfoque centrado en la tecnología para lograr resultados excepcionales en el mundo digital.",

    }
  ];

  export const experiencias = [
    {
      image: "./imagenes/mision.png",
      empresa: "Nuestra misión es ofrecer soluciones de marketing digital innovadoras y personalizadas que impulsen el éxito de nuestros clientes. Nos comprometemos a ser un socio estratégico confiable, utilizando creatividad, tecnología y análisis de datos para superar expectativas y generar resultados tangibles. Valoramos la transparencia, la integridad y la ética, y nos esforzamos por construir relaciones sólidas basadas en la confianza y el respeto mutuo.",
    }
  ];

  export const valores = [
    { 
      empresa: "Confianza",
    },
    { 
      empresa: "Respeto",
    },
    { 
      empresa: "Creatividad",
    },
    { 
      empresa: "Transparencia",
    },
    { 
      empresa: "Trabajo en equipo",
    },
    { 
      empresa: "Integridad",
    }
  ];

  export const nuevos = [
    {
      nombre: "Soluciones Personalizadas",
      image: "./imagenes/js.png",
      ok: "Entendemos que cada negocio es único, por lo tanto, adaptamos nuestros servicios a tus necesidades específicas. Nos aseguraremos de brindarte soluciones que se ajusten a tus objetivos y requisitos.",
    },
    {
      nombre: "Resultados Medibles",
      image: "./imagenes/photoshop.png",
      ok: "Nos enfocamos en obtener resultados tangibles y medibles para tu negocio. Utilizamos herramientas de análisis y seguimiento para evaluar el rendimiento de tus estrategias digitales y realizar ajustes según sea necesario.",
    }, 
    {
      nombre: "Atención al Cliente",
      image: "./imagenes/react.png",
      ok: "Valoramos la satisfacción de nuestros clientes y nos esforzamos por brindar un excelente servicio al cliente. Nos aseguramos de responder a tus consultas, escuchar tus inquietudes y brindarte el apoyo necesario en todo momento.",
    }
  ];

  export const testimoniales = [
    {
      name: "Pascal Tremblay",
      company:"un verdadero placer trabajar con él y esperamos trabajar con él nuevamente. Definitivamente es el tipo de diseñador en el que puede confiar con un proyecto de principio a fin.",
      image: "./imagenes/user-02.jpg",
    },
    {
      name: "React and Firebase",
      company:"Lorem ipsum dolor sit amet consectetur adipisicing eliteius?",
      image: "./imagenes/user-03.jpg",
    },
    {
      name: "React and Firebase",
      company:"Lorem ipsum dolor sit amet consectetur adipisicing eliteius?",
      image: "./imagenes/user-03.jpg",
    }
  ];